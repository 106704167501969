/**
 * optionsGauge
 * ===========
 * Muestra en porcentaje y lo pinta
 * de acuerdo a su valor de 0 - 100
 * @param {Number} percentValue
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=gauge-progress
 */
export const optionsGauge = (percentValue = 50, porcentajeColor = "#000") => {
  /**
   * getColor
   * @param {Number} dataValue
   * from green to red color depend on percentage
   * https://stackoverflow.com/a/17268489/9868383
   */
  // function getColor(dataValue) {
  //   const value = 100 - dataValue / 100;
  //   //value from 0 to 1
  //   var hue = ((1 - value) * 120).toString(10);
  //   return ["hsl(", hue, ",100%,50%)"].join("");
  // }

  return {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 2,
        itemStyle: {
          color: porcentajeColor
        },
        progress: {
          show: true,
          roundCap: true,
          width: 12
        },
        pointer: {
          show: false
        },
        detail: {
          offsetCenter: [0, "15%"],
          valueAnimation: true,
          fontSize: 14,
          formatter: function (value) {
            return value + " %";
          }
        },
        data: [
          {
            value: percentValue
          }
        ]
      }
    ]
  };
};

/**
 * optionsPie
 * ==========
 * Genera los puntos para grafico Pie
 * @param {Array} data
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=pie-simple
 */
export const optionsPie = (data = []) => {
  return {
    title: {
      text: "Región Tacna",
      // subtext: "2021",
      left: "center"
    },
    tooltip: {
      trigger: "item"
    },
    legend: {
      orient: "vertical",
      left: "left"
    },
    series: [
      {
        name: "Provincia",
        type: "pie",
        radius: "50%",
        label: {
          formatter: "{c}",
          position: "inside",
          fontSize: 15
        },
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  };
};

/**
 * optionsBar - barRainfall
 * =========================
 * @returns {Object} formato de echarts
 * https://echarts.apache.org/examples/en/editor.html?c=bar1
 */
export const optionsBar = (distritos = [], numerador = [], denominador = [], porcentaje = []) => {
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      }
    },
    legend: {
      data: ["Numerador", "Denominador"],
      top: "top",
      selected: {
        Numerador: false,
        Denominador: false
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      max: 100
    },
    yAxis: {
      type: "category",
      data: distritos,
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    series: [
      {
        name: "Porcentaje",
        type: "bar",
        data: porcentaje,
        showBackground: true,
        label: {
          show: true,
          formatter: "{c} %",
          fontSize: 10,
          offset: [10, 1]
        }
      },
      {
        name: "Numerador",
        type: "bar",
        data: numerador
      },
      {
        name: "Denominador",
        type: "bar",
        data: denominador
      }
    ]
  };
};

export const provinciaGraphic = (pieData = []) => {
  const object = {
    xAxis: {
      type: "category",
      data: pieData.map((p) => p.name),
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    yAxis: {
      type: "value",
      name: "Porcentaje",
      max: 100,
      axisLabel: {
        margin: 8.5,
        fontSize: 10
      }
    },
    series: [
      {
        label: {
          show: true,
          formatter: "{c} %",
          fontSize: 10,
          offset: [0, -5]
        },
        data: pieData.map((p) => p.value),
        type: "bar",
        barWidth: 45,
        showBackground: true
      }
    ]
  };
  return object;
};

export const optionsLine = (meses = [], porcentajes = []) => {
  return {
    title: {
      text: "%"
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985"
        }
      }
    },
    legend: {
      data: ["Porcentaje"]
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: meses
      }
    ],
    yAxis: [
      {
        type: "value",
        max: 100
      }
    ],
    series: [
      {
        name: "Porcentaje",
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: {
          focus: "series"
        },
        label: {
          show: true,
          position: "top"
        },
        data: porcentajes
      }
    ]
  };
};
